import React, { useEffect } from "react"

import Sodexo from "../images/sodexo.svg"
import Forbes from "../images/sdx/forbes.png"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./sdx.scss"

const Sdx = () => {
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }, [])
  return (
    <Layout>
      <SEO title={"Sodexo"} />
      <div className="sdxWrapper">
        <div className="sdxContent">
          <h1 className="sdxContentHeading">
            The Missing Fourth Group Among FM, HR, and IT
          </h1>
          <p className="sdxContentDesc">
            Learn how flourishing employees fit into the workplace experience
            puzzle. Solve the biggest challenges in FM.
            <br />
            <br />
            <b>
              Book your free 30-minute discovery session with the SHAPE team.
            </b>
          </p>
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/shapeteam/30min"
            style={{ minWidth: 320, height: 700 }}
          ></div>
          <br />
          <br />
          <div className="sdxContentFooter">
            <div>
              <p className="sdxContentFooterHeading">In collaboration with</p>
              <img src={Sodexo} alt="Sodexo" />
            </div>
            <a
              href="https://www.forbes.com/sites/sheilacallaham/2023/09/19/startup-advances-employee-productivity-measurement-captures-attention-of-global-leaders/?sh=2bdafb8a41c9"
              target="_blank"
            >
              <div>
                <p className="sdxContentFooterHeading">Featured in</p>

                <img src={Forbes} alt="Forbes" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sdx
